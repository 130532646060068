import {FaInstagram, FaTelegram, FaTwitter} from "react-icons/all";
import EitaaIcon from "../../img/icons/eitaa"
import {WhatsApp} from "@material-ui/icons";

export const DETAILS = {
    name: "فروشگاه اصالت",
    shortName: "اصالت",
    footerName: "اصالت شاپ",
    description: "",
    shortAbout: " ما کارمان را از فروش کتاب، محصولات فرهنگی و کالای دیجیتال به صورت عمده آغاز کرده ایم و اینک پس از دو دهه تجربه با بهره گیری از دانش مدیرانمان در مارکتینگ، با حذف واسطه ها و دلال ها می خواهیم کالایی با اصالت را با قیمتی ارزان تر《با پلتفرم تجارت الکترونیک》به دست مصرف کننده نهایی برسانیم. امید است که رضایت شما باعث توسعه فروش کالای ایرانی و اشتغال زایی بیشتر هموطنان عزیزمان شود.",
    veryShortAbout: "",
    link: "https://esalatshop.ir",
    mobile: [],
    phone: ["02537732297" , "09027723652"],
    email: [],
    postalCode: [],
    address: "قم، خیابان معلم غربی، نرسیده به معلم 3، فروشگاه اصالت",
    supportDescription: "",
    workingHours: [],
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d6565.606657424053!2d50.882747!3d34.63441!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDM4JzAzLjkiTiA1MMKwNTInNTcuOSJF!5e0!3m2!1sen!2s!4v1709200581250!5m2!1sen!2s",
    socialMedia: [
        {
            title: 'واتس اپ',
            value: '09380637888',
            name: 'whatsapp',
            link: 'https://wa.me/message/6UQJRM4IVQVOF1',
            icon: <WhatsApp/>,
            isPrimary: true
        },
        {
            title: 'تلگرام',
            value: 'EsalatShop_admin',
            name: 'telegram',
            link: 'https://t.me/EsalatShop_admin',
            icon: <FaTelegram/>,
            isPrimary: true
        },
        {
            title: 'ایتا',
            value: '09357195494',
            name: 'eitaa',
            link: 'https://eitaa.com/EsalatShop_admin',
            icon: <EitaaIcon/>,
            isPrimary: true
        },
        {
            title: 'اینستاگرام',
            value: 'esalatshop.ir',
            name: 'instagram',
            link: "https://instagram.com/esalatshop.ir",
            icon: <FaInstagram/>,
            isPrimary: true
        },
        {
            title: 'توئیتر (ایکس)',
            value: 'EsalatShop',
            name: 'instagram',
            link: "https://instagram.com/EsalatShop",
            icon: <FaTwitter/>,
            isPrimary: true
        },
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    enamad: {
        link: "https://trustseal.enamad.ir/?id=464270&Code=KkNykIy7SRQztXvMD8LMZpsXDK0RI72w",
        src: "https://trustseal.enamad.ir/logo.aspx?id=464270&Code=KkNykIy7SRQztXvMD8LMZpsXDK0RI72w",
        code: "KkNykIy7SRQztXvMD8LMZpsXDK0RI72w"
    },
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
