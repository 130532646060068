import Axios from "axios";
import { baseUrl } from "../../configs/requestConfig";
import Cookies from "universal-cookie";
import ObjectToURL from "./ObjectToUrl";
import axios from "axios";

// export default async function GetRequest(url, header = initialHeader, locale = false) {
//     const cookies = new Cookies();
//     if (locale)
//         header['locale'] = cookies.get('locale');
//     else
//         header['locale'] = cookies.get('language');
//     const config = {
//         headers: header,
//         withCredentials: true
//     }
//     return await Axios.get(baseUrl + url, config);
// }

/**
 * Makes a GET request to the server using axios.
 *
 * @async
 * @param {string} url - The URL to make the request to.
 * @param {object} [data] - The data to send with the request.
 * @param {object} [headers=initialHeaders] - The headers to include in the request.
 * @param {boolean} [locale=false] - Whether to use the locale cookie value or the language cookie value.
 * @returns {Promise<object>} A promise that resolves with the response data.
 * @throws {Error} Throws an error if the request fails or is canceled.
 */
const initialHeader = {};
const getRequest = async (
  url,
  header = initialHeader,
  locale = false,
  data
) => {
  try {
    const cookies = new Cookies();
    const rawLocale = locale ? cookies.get("locale") : cookies.get("language");
    const decodedLocale = rawLocale ? rawLocale.replace(/\+/g, " ") : "";
    header.locale = decodedLocale;

    const processedURL = ObjectToURL(`${baseUrl}${url}`, data);

    const requestConfig = { headers: { ...initialHeader, ...header } };
    const response = await axios.get(processedURL, requestConfig);

    return response;
  } catch (error) {
    throw error;
  }
};

export default getRequest;
