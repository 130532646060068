import Axios from "axios";
import { baseUrl } from "../../configs/requestConfig";
import Cookies from "universal-cookie";

const initialHeader = {};
export default async function DeleteRequest(url, header = initialHeader) {
  const cookies = new Cookies();
  header["locale"] = cookies.get("language");
  const config = {
    headers: header,
    withCredentials: true,
  };
  return await Axios.delete(baseUrl + url, config);
}
console.log(initialHeader);
